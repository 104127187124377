import React, { Suspense } from 'react';
import { BaseComponent } from "themes/components";
import Header from '../components/HomeComponents/Header'
import Footer from '../components/HomeComponents/Footer'
import { enquireScreen, unenquireScreen } from 'enquire-js'
import { Layout } from 'antd';
import Head from 'next/head';
import ellipsize from 'ellipsize';


import { FacebookProvider, CustomChat } from 'react-facebook';
// import style from 'themes/scss/default.scss'
class Main extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    };
    let { data = {} } = this.props;
  }


  componentDidMount() {
    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state;
      if (isMobile !== Boolean(mobile)) {
        this.setState({
          isMobile: Boolean(mobile),
        })
      }
      this.setStore("isMobile", mobile)
    })
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler)
  }

  render() {
    let { data = {}, metaData = {} } = this.props;
    let {keywords = []} = metaData;
    let seoTitle = ellipsize(metaData.title, 50)  +" | " + data.siteTitle
    let seoDescription = ellipsize(metaData.description, 155)
    return (<React.Fragment>
      <FacebookProvider appId={data.fbAppId || "1"} chatSupport>
        <Head>
           {/* Meta SEO */}
           <title>{seoTitle}</title>
            <meta name="description" content={seoDescription || data.slogan || ""} />
            <meta name="keywords" content={`${keywords.join(", ")}`} />

          {/* Open graph */}
          <meta property="og:url" content={`${data.siteUrl}${metaData.url}` || ""} />
          <meta property="og:type" content={metaData.type || "article"} />
          <meta property="og:title" content={seoTitle} />
          <meta property="og:description" content={metaData.description || data.slogan} />
          <meta property="og:image" content={metaData.image || data.logo} />

        </Head>
        <div id="home">
          <main>
            <Layout>
              <Header isMobile={this.state.isMobile} data={this.props.data} />
              <div className="content-test">
                {this.props.children}
              
              <Footer data={this.props.data} />
              </div>
            </Layout>
          </main>
        </div>
        <CustomChat pageId={data.fbPageId} minimized={false}/>
      </FacebookProvider>
    </React.Fragment>
    );
  }
}
export default BaseComponent.export({
  mapStateToProps: () => {}
})(Main);