import BaseApi from './Base'

class Menu extends BaseApi {
  constructor(context) {
    super(context)
    this.name = 'menus'; //kế thừa CRUD từ thằng cha
  }
  
  async getForHeader() {
    const url = this.route(`api.${this.name}.getForHeader`).href
    return await this.Request.get(url)
  }

  async getRightMenu() {
    const url = this.route(`api.${this.name}.getRightMenu`).href
    return await this.Request.get(url)
  }
}

export default Menu