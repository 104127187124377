import BaseApi from './Base'

class Setting extends BaseApi {
  constructor(context) {
    super(context)
    this.name = 'settings'; //kế thừa CRUD từ thằng cha
  }

  async getPublicSetting(){
    const url = this.route(`api.${this.name}.getPublicSetting`).href;
    let result = await this.Request.get(url);
    return result;
  }

  async getBreadcrumb(data) {
    const url = this.route(`api.${this.name}.getBreadcrumb`, data).href;
    let result = await this.Request.get(url, data);
    return result;
  }

  async createAboutUsPage(data) {
    const url = this.route(`api.${this.name}.createAboutUsPage`, data).href;
    let result = await this.Request.put(url, data);
    return result;
  }
}

export default Setting