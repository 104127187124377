import BaseApi from './Base'

class Product extends BaseApi {
  constructor(context){
    super(context)
    this.name = 'products'; //kế thừa CRUD từ thằng cha
}

async getNewestProducts(data){
  const url = this.route(`api.${this.name}.getNewestProducts`,data).href
  return await this.Request.get(url, data)
}
async getNewestProductsByCategoryId(data){
  const url = this.route(`api.${this.name}.getNewestProductsByCategoryId`,data).href
  return await this.Request.get(url, data)
}
async getByCategoryId(data){
  const url = this.route(`api.${this.name}.getByCategoryId`,data).href
  return await this.Request.get(url, data)
}
async getTopSellProducts(data) {
  const url = this.route(`api.${this.name}.getTopSellProducts`,data).href
  return await this.Request.get(url, data)
}
async getRelatedById(data) {
  const url = this.route(`api.${this.name}.getRelatedById`,data).href
  return await this.Request.get(url, data)
}
async getDetail(data){
  const url = this.route(`api.${this.name}.getDetail`,data).href
  return await this.Request.get(url, data)
}
async getAll(data) {
  const url = this.route(`api.${this.name}.getAll`,data).href
  return await this.Request.get(url, data)
}


}

export default Product