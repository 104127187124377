import { BaseComponent } from "themes/components";
import { Layout, Icon, Button } from "antd";
import { Row, Col, Divider } from "antd";
import slug from "slug";

import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
const { Footer } = Layout;
class FooterComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
    };
  }

  redirectMenu = ({ name, type, value } = {}) => {
    //Nếu không phải link thì gọi route trong hệ thống
    if (type !== "url") {
      this.redirect(`frontend.home.${type}`, {
        slug: slug(name) || type,
        id: value,
      });
      return false;
    }
    let win = window.open(value, "_self");
    win.focus();
  };
  renderBottomFooter = (data = {}) => {
    const {
      faceLink = "",
      instagramLink = "",
      youtubeLink = "",
    } = data;
    return (
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col>
          Copyright © 2020 NUSEE
        </Col>
        <Col>
          <a href={faceLink} target="_blank">
            <Button>
              <Icon type="facebook" theme="filled"/>
            </Button>
          </a>
          <a href={instagramLink} target="_blank">
            <Button>
              <Icon type="instagram" theme="filled"/>
            </Button>
          </a>
          <a href={youtubeLink} target="_blank">
            <Button>
              <Icon type="youtube" theme="filled"/>
            </Button>
          </a>
        </Col>
      </Row>
    );
  };
  render() {
    const { data = {} } = this.props;
    let _menus = data.menus || [];
    let {
      workTimes = "",
      slogan = "",
      footer_imgs,
      logo = ""
    } = data;
    footer_imgs = Array.isArray(footer_imgs)? footer_imgs : JSON.parse(footer_imgs || "");
    return (
      <LazyLoadComponent>
        <Footer className="footer">
          <Row type="flex" justify="space-around" gutter={[16, 16]}>
            <Col xs={24} md={7}>
            <a href="/" style={{ display: "contents" }}>
                <LazyLoadImage
                  src={`/thumb?src=${logo}&w=300`}
                  delayTime={0}
                  alt={data.siteName}
                  effect="blur"
                  className="footer-logo"
                />
              </a>
              <br/>
              <br/>
              <p>{slogan}</p>
            </Col>

            <Col xs={24} md={4}>
              <h1>Danh mục</h1>
              <ul>
                {_menus.map((item, index) => {
                  if (item.parentId) return;
                  return (
                    <li key={index}>
                      <Button onClick={() => this.redirectMenu(item)}>
                        {item.name}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </Col>

            <Col className="flicker" xs={24} md={7}>
              <h1>Ảnh nổi bật</h1>
              <Row type="flex" justify="space-around" gutter={[16, 16]}>
                {Array.isArray(footer_imgs) ? footer_imgs.map((item = {}, index) => (
                  <Col xs={8} key={index}>
                  <div>
                    <LazyLoadImage src={`/thumb?src=${item.url}&w=200`} delayTime={0}/>
                  </div>
                </Col>
                )): ""}
              </Row>
            </Col>

            <Col xs={24} md={5}>
              <h1>giờ mở cửa</h1>
              {workTimes.split("+").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </Col>
          </Row>
          <Divider style={{ margin: "0px" }} />
          {this.renderBottomFooter(data)}
        </Footer>
      </LazyLoadComponent>
    );
  }
}

export default FooterComponent;
