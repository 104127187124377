import React from 'react';
import MenuService from 'themes/services/api/Menu';
import SettingService from 'themes/services/api/Setting';
import BannerService from 'themes/services/api/Banner';
import ProductService from 'themes/services/api/Product';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import to from 'await-to-js'
class InitData extends React.Component {
  constructor(props) {
    super(props);
  }

  static async getInitData(ctx) {
    let menuObj = new MenuService(ctx)
    let settingObj = new SettingService(ctx);
    let BannerServiceObj = new BannerService(ctx);
    let ProductServiceObj = new ProductService(ctx);

    let [
      error,
      [menus = [], rightMenu = [], setting = {}, banner = {}, topSells = []] = []
    ] = await to(
      Promise.all([
        menuObj.getForHeader(),
        menuObj.getRightMenu(),
        settingObj.getPublicSetting(),
        BannerServiceObj.getPublicBanner(),
        ProductServiceObj.getTopSellProducts()
      ])
    )
    if (error) return {};
    return {
      ...setting,
      menus,
      banner,
      rightMenu,
      topSells
    }
  }

  static async getBreadcrumb(ctx, { type, id } = {}) {
    let settingObj = new SettingService(ctx);
    let [error, breadcrumb = []] = await to(settingObj.getBreadcrumb({ type, id }));
    return breadcrumb;
  }

  render() {
    return ("");
  }
}

export default InitData;