import BaseApi from './Base'

class Advertisement extends BaseApi {
  constructor(request){
    super(request)
    this.name = 'banners'; //kế thừa CRUD từ thằng cha
}
  async getPublicBanner (){
    const url = this.route(`api.${this.name}.getPublicBanner`).href
  return await this.Request.get(url)
  }
}

export default Advertisement