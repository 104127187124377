import React from "react";
import { BaseComponent } from "themes/components";
import TweenOne from "rc-tween-one";
import { Menu, Button, Icon, Row, Col, Divider } from "antd";
import slug from "slug";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";

const { SubMenu } = Menu;
const dataSource = {
  wrapper: { className: "header" },
  page: { className: "home-page" },
  logo: {
    className: "header-logo",
  },
  Menu: {
    className: "header-menu",
  },
  mobileMenu: { className: "header-mobile-menu" },
};

class header extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      // selectedKeys: ["home"],
      reload: false,
      openKeys: [],
    };
    let { data = {} } = this.props;
    this.menus = data.menus || [];
    this.logo = data.logo || "/uploads/root/logo/baoboi.png";
  }

  onChangeOpenKeys = (lists = []) => {
    if (lists.length) {
      let openKeys = [lists[lists.length - 1]];
      this.setState({ openKeys: openKeys });
    } else {
      this.setState({ openKeys: [] });
    }
  };

  redirectMenu = ({ name, type, value } = {}) => {
    //Nếu không phải link thì gọi route trong hệ thống
    if (type !== "url") {
      this.redirect(`frontend.home.${type}`, {
        slug: slug(name) || type,
        id: value,
      });
      return false;
    }
    let win = window.open(value, "_self");
    win.focus();
  };

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  renderMenu = (node, index) => {
    let allData = this.menus || [];
    if (!Array.isArray(allData)) return false;
    let data = node;
    if (index == 0) {
      data = allData.filter((item) => item.parentId == null);
    }

    let result = data.map((item) => {
      let childs = allData.filter((child) => child.parentId == item.id);
      if (!childs.length) {
        return (
          <Menu.Item key={item.id} onClick={() => this.redirectMenu(item)}>
            {item.name}
          </Menu.Item>
        );
      }
      return (
        <SubMenu key={item.name} title={item.name}>
          {this.renderMenu(childs, index + 1)}
        </SubMenu>
      );
    });
    return result;
  };

  renderTopHeader = (data = {}) => {
    const {
      phones = "",
      emails = "",
    } = data;
    const styleObj = { margin: "8px" };
    return (
      <Row type="flex" justify="end" className="topHeader">
        <Col>
          <Icon type="phone" style={styleObj} /> {phones.split("+")[0] || ""}
          <Icon type="mail" style={{ ...styleObj, marginLeft: "40px" }} />{" "}
          {emails.split("+")[0] || ""}
        </Col>
      </Row>
    );
  };
  render() {
    const { isMobile, data, ...props } = this.props;
    const { phoneOpen } = this.state;
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <div>
        {this.renderTopHeader(data)}
        <TweenOne
          component="header"
          animation={{ opacity: 0, type: "from" }}
          {...dataSource.wrapper}
          {...props}
        >
          <div
            {...dataSource.page}
            className={`${dataSource.page.className}${
              phoneOpen ? " open" : ""
            }`}
          >
            <TweenOne
              animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
              {...dataSource.logo}
            >
              <a href="/" style={{ display: "contents" }}>
                <LazyLoadImage
                  src={`/thumb?src=${this.logo}&w=300`}
                  delayTime={0}
                  alt={data.siteName}
                />
              </a>
            </TweenOne>
            {isMobile && (
              <div
                {...dataSource.mobileMenu}
                onClick={() => {
                  this.phoneClick();
                }}
              >
                <em />
                <em />
                <em />
              </div>
            )}
            <TweenOne
              {...dataSource.Menu}
              animation={
                isMobile
                  ? {
                      x: 0,
                      height: 0,
                      duration: 300,
                      onComplete: (e) => {
                        if (this.state.phoneOpen) {
                          e.target.style.height = "auto";
                        }
                      },
                      ease: "easeInOutQuad",
                    }
                  : null
              }
              moment={moment}
              reverse={!!phoneOpen}
            >
              <Menu
                mode={isMobile ? "inline" : "horizontal"}
                onOpenChange={this.onChangeOpenKeys}
                openKeys={this.state.openKeys}
                // defaultSelectedKeys={["Dầu gội - Dầu xả"]}
                // selectedKeys={this.state.selectedKeys}
              >
                {this.renderMenu(this.menus, 0)}
              </Menu>
            </TweenOne>
          </div>
        </TweenOne>
      </div>
    );
  }
}

export default header;
